import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session)

	const signIn = async (values) => {
		try {
			const resp = await apiSignIn(values)
			if (resp.data) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if (resp.data.token) {
					dispatch(setUser({
						avatar: '',
						username: resp.data.userName,
						authority: [resp.data.G],
						email: ''
					} ))
				}
				if (resp.data.G==="admin"){
					const redirectUrl = query.get(REDIRECT_URL_KEY)
					navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
					return {
						status: 'success',
						message: ''
					}
				}else{
					const redirectUrl = query.get(REDIRECT_URL_KEY)
					navigate(redirectUrl ? redirectUrl : appConfig.authenticatedUserEntryPath)
					return {
						status: 'success',
						message: ''
					}
				}
				
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				const { token } = resp.data
				console.log(resp.data)
				dispatch(onSignInSuccess(token))
				if (resp.data.userName) {
					dispatch(setUser(resp.data.userName || {
						avatar: '',
						username: resp.data.userName,
						authority: [resp.data.G],
						email: ''
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		handleSignOut()
		await apiSignOut()
		window.location.reload()
		
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut
	}
}

export default useAuth