import ApiService from './ApiService'

export async function apiGetMessagesData() {
    return ApiService.fetchData({
        url: '/api2/MessageList/',
        method: 'get',

    })
}

export async function apiGetTempMsgList() {
    return ApiService.fetchData({
        url: '/api2/TML/',
        method: 'get',

    })
}

export async function apiSentTempMsgList(mob) {
    return ApiService.fetchData({
        url: '/api2/mts/' + mob.mob + '/' + mob.id + '/' +mob.name ,
        method: 'get',

    })
}

export async function apiSentTempMsg(mob) {
    return ApiService.fetchData({
        url: '/api2/tm/' + mob,
        method: 'get',

    })
}

export async function apiSentEndMsg(mob) {
    return ApiService.fetchData({
        url: '/api2/em/' + mob,
        method: 'get',

    })
}
//apiSentEndMsg
export async function apiGetContactsData() {
    return ApiService.fetchData({
        url: '/api2/contact/',
        method: 'get',

    })
}

export async function apiGetNewMessagesData(id) {
    return ApiService.fetchData({
        url: '/api2/MessageList/?id__gt=' + id,
        method: 'get',

    })
}

export async function apiGetNewContactsData(id) {
    return ApiService.fetchData({
        url: '/api2/contact/?id__gt=' + id,
        method: 'get',

    })
}


export async function apiGetContactsDataUpdate(id) {
    return ApiService.fetchData({
        url: '/api2/contact/?id=' + id,
        method: 'get',

    })
}
export async function apiPutContactsData(data) {
    return ApiService.fetchData({
        url: '/api2/contact/',
        method: 'put',
        data
    })
}

export async function apiPostMessageData(data) {
    return ApiService.fetchData({
        url: '/api2/sendmsg/',
        method: 'post',
        data
    })
}
