import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash';

import { apiSentEndMsg, apiGetMessagesData, apiSentTempMsg, apiGetContactsData, apiGetNewMessagesData, apiPutContactsData, apiGetNewContactsData, apiPostMessageData, apiGetContactsDataUpdate, apiSentTempMsgList, apiGetTempMsgList } from 'services/chat'

export const sendTempMsgList = createAsyncThunk('chat/data/sendTempMsgList', async (mob,{ rejectWithValue }) => {

    try {
        //console.log(mob)
        const response = await apiSentTempMsgList(mob)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const sendTempMsg = createAsyncThunk('chat/data/sendTempMsg', async (mob, { rejectWithValue }) => {

    try {
        const response = await apiSentTempMsg(mob)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

export const sendEndMsg = createAsyncThunk('chat/data/sendEndMsg', async (mob, { rejectWithValue }) => {

    try {
        const response = await apiSentEndMsg(mob)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getMessagesTempleteList = createAsyncThunk('chat/data/getMessagesTempleteList', async (data, { rejectWithValue }) => {

    try {
        const response = await apiGetTempMsgList()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getMessagesData = createAsyncThunk('chat/data/getMessagesData', async (data, { rejectWithValue }) => {

    try {
        const response = await apiGetMessagesData()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getContactsData = createAsyncThunk('chat/data/getContactsData', async (data, { rejectWithValue }) => {
    try {
        const response = await apiGetContactsData()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const getNewMessagesData = createAsyncThunk('chat/data/getNewMessagesData', async (id, { rejectWithValue }) => {
    try {
        const response = await apiGetNewMessagesData(id)


        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})
export const getNewContactsData = createAsyncThunk('chat/data/getNewContactsData', async ({ rejectWithValue }) => {
    try {
        //  const response = await apiGetNewContactsData(id)
        const response = await apiGetContactsData()

        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }

})


export const getContactsDataUpdate = createAsyncThunk('chat/data/getContactsDataUpdate', async (id, { rejectWithValue }) => {
    try {
        const response = await apiGetContactsDataUpdate(id)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }

})

export const PutContactsData = createAsyncThunk('chat/data/PutContactsData', async (id, { rejectWithValue }) => {
    try {
        const response = await apiPutContactsData(id)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const postMessageData = createAsyncThunk('chat/data/postMessageData', async (data, { rejectWithValue }) => {
    try {
        const response = await apiPostMessageData(data)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'chat/data',
    initialState: {
        loading: true,
        messageList: null,
        contactList: null,
        templeteMessageList:null,
        selected: {
            contact: {},
            messages: []
        }


    },
    reducers: {
        setSelected: (state, action) => {

            state.selected.contact = action.payload.contact
            const msgs = state.messageList ? state.messageList.filter((msg) => msg.contacts === action.payload.contact.id) : []
            state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
        },
        UpdateContacts: (state, action) => {

            state.contactList = _.orderBy(action.payload, ['lastupdate'], ['desc']);

        },
        AddNewMessage: (state, action) => {
          

                const newObjects = action.payload;
                const currentState = state.messageList;
                const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                //return { ...state, contactList: newState }


                state.messageList = newState

                if (state.selected.messages && newState) {
                    const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                    state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                }

          
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(sendEndMsg.fulfilled, (state, action) => {
                //sendEndMsg   state.messageList = _.orderBy(action.payload, ['id'], ['desc']);
                if (action.payload) {

                    const newObjects = action.payload;
                    const currentState = state.messageList;
                    const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                    //return { ...state, contactList: newState }


                    state.messageList = newState

                    if (state.selected.messages && newState) {
                        const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                        state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                    }

                }
                state.loading = false
            })
            .addCase(postMessageData.fulfilled, (state, action) => {
                //sendEndMsg   state.messageList = _.orderBy(action.payload, ['id'], ['desc']);
                if (action.payload) {

                    const newObjects = action.payload;
                    const currentState = state.messageList;
                    const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                    //return { ...state, contactList: newState }


                    state.messageList = newState

                    if (state.selected.messages && newState) {
                        const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                        state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                    }

                }
                state.loading = false
            })
            .addCase(postMessageData.pending, (state, action) => {
                state.loading = false
            })
            .addCase(getMessagesTempleteList.fulfilled, (state, action) => {
                state.templeteMessageList = action.payload
            })
            .addCase(getMessagesData.fulfilled, (state, action) => {
                state.messageList = _.orderBy(action.payload, ['id'], ['desc']);
                state.loading = false
            })
            .addCase(getMessagesData.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getMessagesData.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(getContactsData.fulfilled, (state, action) => {
               
                state.contactList = _.orderBy(action.payload, ['lastupdate'], ['desc']);


            })
            // .addCase(getContactsData.pending, (state, action) => {
            //     state.loading = false
            // })
            // .addCase(getContactsData.rejected, (state, action) => {
            //     state.loading = true
            // })

            .addCase(getNewContactsData.fulfilled, (state, action) => {
                const newObjects = _.orderBy(action.payload, ['lastupdate'], ['desc']);
                state.contactList = newObjects
                // if (action.payload){
                //     const newObjects = action.payload;
                //     const currentState = state.contactList;
                //     const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                //     //return { ...state, contactList: newState }
                //     state.contactList = newState

                // }

            })
            //
            .addCase(getContactsDataUpdate.fulfilled, (state, action) => {
                state.selected.contact = action.payload
            })
            // .addCase(getNewContactsData.pending, (state, action) => {
            //     state.loading = true
            // })
            // .addCase(getNewContactsData.rejected, (state, action) => {
            //     state.loading = true
            // })

            .addCase(getNewMessagesData.fulfilled, (state, action) => {
            
                if (action.payload.length > 0) {
             
                    const newObjects = action.payload;
                    const currentState = state.messageList;
                    const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                    //return { ...state, contactList: newState }


                    state.messageList = newState

                    if (state.selected.messages && newState) {
                        const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                        state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                    }

                }


            })
        // .addCase(getNewMessagesData.pending, (state, action) => {
        //     state.loading = true
        // })
        // .addCase(getNewMessagesData.rejected, (state, action) => {
        //     state.loading = true
        // })

    },
})
export const {
    setSelected,
    AddNewMessage,
    UpdateContacts,
} = dataSlice.actions
export default dataSlice.reducer
